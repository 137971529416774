<template>
<v-app>
  <div id="app" class="imgBackground">    
    <header class="app-header b2">
      <div class="shadow-lg p-3 mb-5 bg-white rounded">        
      <NavBar />
      </div>
    </header>
    <main>
      <router-view></router-view>      
    </main>  
  </div>
  </v-app>
</template>

<script>

import NavBar from './components/navBar';

export default{
  name: 'App',
  components: {
    NavBar
   // Footer     
  },
 
}

</script>
<style scoped>
.app-header{
  height:80px;  
}
.imgBackground{
  background-image: url("./images/mainPagePic.jpg");    
  background-size: cover;
  background-repeat: repeat;
  height: 100%;
}
</style>
<style lang="scss">
  
  @import './../node_modules/bootstrap/scss/bootstrap.scss';
  @import './../node_modules/bootstrap-vue/src/index.scss';
</style>