<template>
  <div class="container">
    <div class="title titleSingle">
      <div></div>
      <div>
        <h3 class="titleText">
          {{ this.name }}
        </h3>
      </div>
      <div></div>
    </div>
    <div class="displayTextbox ">    

      <v-data-table
        :headers="headers"
        :items="attendance"
        calculate-widths:true
        fixed-header:true        
      >       
        <template v-slot:item.startDate="{ item }">
          <span> {{ getFriendlyDate(item.startDate) }}</span>
        </template>
        <template v-slot:item.endDate="{ item }">
          <span> {{ getFriendlyDate(item.endDate, item.startDate) }}</span>
        </template> 
        <template v-slot:item.attending="{ item }">
          <v-select
            :items="options"
            label="Attending"
            v-model="item.attending"
            class="attending"
            :menu-props="{ center: true, overflowY: true }"
            hide-details="auto"
          ></v-select>
          <v-select
            v-if="showDayOptions(item)"
            label="days"
            :items="item.displayData.dayStrings"
            v-model="item.displayData.selectedDayDisplay"
            hide-details="auto"
            multiple
            class="attending"
            calculate-widths:true
          >
          </v-select>
        </template>
        <template v-slot:item.note="{ item }">
          <v-textarea v-model="item.note" rows="1"></v-textarea>
        </template>
      </v-data-table>
      <div style=" display:flex; justify-content:center; align-items:center;">
        <v-btn color="blue-grey" dark shaped @click="onBack">Back</v-btn>
        <v-btn
          :loading="submittingData"
          :disabled="submittingData"
          color="green"
          class="ma-2 white--text"
          @click="saveAttendance"
          >Submit
        </v-btn>        
      </div>
    </div>
    <div
        class="alert alert-primary"
        style="text-align: center"
        role="alert"
        v-show="message"
      >
        {{ message }}
    </div>
    <div
        class="alert alert-danger"
        style="text-align: center"
        role="alert"
        v-show="errorMessage"
      >
        {{ errorMessage }}
    </div>
    
  </div>
</template>
<style lang="css" scoped>
  @import "./../design/table.scss";
</style>

<style lang="scss" scoped>
::v-deep td.headerCol{  
    min-width: 150px;    
    padding: 15px 2px 15px 10px;
    text-align: center;
    vertical-align: middle;
    border-spacing:10px;   
}
</style>
<style scoped>


.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
<script>
import { data } from "../shared/data";
import { display } from "../shared/display";
import {
  compareDesc,
  addDays,
  format,
  differenceInDays,
  parseISO
} from "date-fns";

export default {
  name: "Individual",
  data() {
    return {
      name: "",
      id: 3,
      attendance: [],
      options: [
        { text: "Yes", value: "Yes" },
        { text: "No", value: "No" },
        { text: "Maybe", value: "Maybe" }
      ],
      headers: [
        { text: "Title", value: "title", class: "tblIndividual", align:'center' },
        { text: "Start Date", value: "startDate" },
        { text: "End Date", value: "endDate" },
        { text: "Address", value: "address", sortable: false, align: 'center' },
        { text: "Attendance", value: "attending", sortable: false, align:'center' }        
      ],
      message: "",
      errorMessage: "",
      submittingData : false
    };
  },
  async created() {
    this.id = this.$route.params.memberId;
    this.name = this.$route.params.memberName;
    console.log("id = " + this.id);
    if (this.id === undefined) 
    {
      this.errorMessage = "No member information";
    } 
    else 
    {
      this.errorMessage = "";
      await this.getAttendance();
    }
  },
  methods: {
    async getAttendance() {
      this.attendance = [];
      var response = await data.getMemberAttendance(this.id);
      if(response.length == 0)
      {
          this.errorMessage = 'An error has occurred retrieving the data /n' + data.getErrorMessage();
          return; 
      }
      this.attendance = response.memberAttendance.events;
      this.populateDayOptions();
    },
    populateDayOptions() {
      for (var i = 0; i < this.attendance.length; i++) 
      {
        var days = [];
        var arrSelectedDay = [];
        var selectedDays = this.attendance[i].selectedDays;
        var dayOptions = this.attendance[i].dayOptions;
        var startDate = parseISO(this.attendance[i].startDate);
        var endDate = parseISO(this.attendance[i].endDate);
        var displayData = 
        {
          dayStrings:'',
          selectedDayDisplay:[]
        };
        if (dayOptions !== 0)
        {
         // var d = 0;
          var nextDay = startDate;
          var daysOverAWeek = 0;
          var dayDiff = differenceInDays(endDate, startDate);
          while (compareDesc(nextDay, endDate) != -1)
          {
            //get the day of the week and add the date if over a week to display
            var weekDayDisplay = dayDiff < 7 ?format(nextDay, "EEEE") : format(nextDay, "EEEE") + "-" + format(nextDay, "dd");
        
            // get the key for the day of the week
            var weekDayKey = display.getWeekDayKey(format(nextDay, "EEEE"));

            var day = {};
            day["text"] = weekDayDisplay;
            if(differenceInDays(nextDay, startDate) < 7)
            {
              if(weekDayKey & dayOptions)
              {
                //add to days array              
                day["value"] = weekDayKey;
                days.push(day);
                if (selectedDays & weekDayKey) 
                {
                  arrSelectedDay.push(weekDayKey);
                }
              }
            }
            else
            {
              var overAWeekKey = 128 << daysOverAWeek++;
              if(dayOptions & overAWeekKey)
              {
                //over a week so use a different key, based on 1 left move each additional
                day["value"] = overAWeekKey;
                days.push(day);
                if(selectedDays & overAWeekKey)
                {
                  arrSelectedDay.push(overAWeekKey);
                }
              }
            }
            nextDay = addDays(nextDay, 1);
          }
          displayData.dayStrings = days;
          displayData.selectedDayDisplay = arrSelectedDay;        
        }
        this.attendance[i]["displayData"] = displayData;
      }
    },

    getFriendlyDate(date1, date2) {
      if (date1 === null) {
        return display.getFriendlyDate(date2);
      }
      return display.getFriendlyDate(date1);
    },
    showDayOptions(item) {
      var daysDisplayCount =
        item.displayData != undefined &&
        item.displayData.dayStrings != undefined
          ? item.displayData.dayStrings.length
          : 0;
      if (item.attending == "Yes" && daysDisplayCount > 1) {
        console.log(" show day options - Yes");
        return true;
      }
      else
      {
        console.log(" show day options - No");
        return false;
      }
    },
    
    async saveAttendance() {
        this.submittingData = true;
        this.errorMessage = '';
        
        this.updateSelectedDays();
        if(!this.validateSubmitData())
        {          
          this.submittingData = false;
          return;
        }

        var success = await data.postAttendance(this.id, this.attendance);
        this.submittingData = false;
        if(success)
        {
           this.$confirm("Attendance successfully updated", 'Success', 'success');
            this.message = "Attendance successfully updated";
            //redirect to member page
            this.$router.push({name: 'Members'});
        }
        else
        {
            this.errorMessage = data.getErrorMessage();
        }    
    },
    async updateSelectedDays()
    {
        for (var i = 0; i < this.attendance.length; i++) 
        {
            var updatedDays = this.attendance[i].displayData.selectedDayDisplay;
            if(updatedDays == undefined)
            {
                continue;
            }
            var selectedDays = 0;
            for(var k = 0; k < updatedDays.length; ++k)
            {
                selectedDays += updatedDays[k];
            }
            this.attendance[i].selectedDays = selectedDays;
        }

    },
    onBack()
    {
      this.$router.push({name: 'Members'});
    },
    validateSubmitData()
    {
        var errorString = '';
        for(var i = 0; i < this.attendance.length; i++)
        {
          var event = this.attendance[i];
          if(event.attending == 'Yes' && event.startDate != event.endDate){
            if(event.selectedDays <= 0){
                if(event.dayOptions > 0){
                  errorString += "Event '" + event.title + "' with attendance Yes, has no attending days selected \n";
                }
            } 
          
              
          }
        }
        if(errorString != '')
        {
            this.$alert(errorString, 'Validation Error', 'error');
            return false;
        }
      
      return true;
    }, 
  },
 
};
</script>