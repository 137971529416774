<template>
 <div class="container">
    <div class="title titleSingle">
      <div></div>
      <div>
        <h3 class="titleText" style="display:inline-block">
          Invited Events
        </h3>
        <v-button @click="onAddEvent" style="display:inline-block; float:right;cursor:pointer;">
          <v-img  width=40px height=40px :src="require('../images/addIcon2.png')"></v-img>
        </v-button>
      </div>
    
    </div>
  <div class="displayTextbox displayTextboxIE">    
               
      <v-data-table 
        :headers="headers" 
        :items="events"
        @click:row="onEditEvent"
        @page-count="pageCount = $event"
        :items-per-page="15" 
        :page.sync="page"   
        fixed-header:true
        :loading="loadingEvents" loading-text="Loading invited events... Please wait">
        <template v-slot:item.startDate="{ item }">
          <span> {{ getFriendlyDate(item.startDate) }}</span>
        </template>
        <template v-slot:item.endDate="{ item }">
          <span> {{ getFriendlyDate(item.endDate, item.startDate) }}</span>
        </template>
        <template v-slot:item.confirmed="{item}">
          <v-checkbox v-model="item.confirmed" 
              @change="updateConfirmStatus(item)">
          </v-checkbox>          
        </template>
        <template v-slot:item.actionEvent="row">
          <v-btn icon v-on:click="onEditEvent(row.item)">
            <v-icon>mdi-lead-pencil</v-icon>
          </v-btn>
          <v-btn icon v-on:click="onDeleteEvent(row.item)">
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>          
        </template>
      </v-data-table> 
      <div class="text-center pt-2">
        <v-pagination v-model="page" :length="pageCount"></v-pagination> 
      </div>    
      <v-btn color="blue-grey" elevation="10" @click="gotoAdmin">Back</v-btn>
      <div style="padding: 1px 5px 1px 5px; display:inline-block;"></div>
      <v-btn color="blue-grey" elevation="10" @click="confirmAllEventDeletion">Delete Old Events</v-btn>
      <div class="notification is-info" v-show="message">{{ message }}</div>    
  </div>
</div>
</template>

<script>

import { data } from '../shared/data'
import {display} from '../shared/display'

export default {
  name: 'InvitedEvents',
  components: {
    
  },
  data() {  
    return {      
      message: '',
      loadingEvents: false,
      events: [],
      headers:[
        { text: "Title",      value: "title",     sortable:true,    align:'center'},
        { text: "Address",    value: "address",   sortable:false,   align:'center'},
        { text: "Start Date", value: "startDate", sortable: true,   align:'center'},
        { text: "End Date",   value: "endDate",   sortable:true,    align:'center'},
        { text: "Days",       value: "days",      sortable:false,   align:'center'},
        { text: "Confirmed",  value: "confirmed", sortable:true,    align:'center' },
        { text: "Actions",    value: "actionEvent", sortable:false, align:'center'}
      ],
      page: 1,
      pageCount:  0,
      ignoreRowAction: false
    };
  },
  async created () {
    console.log('loading events');      
      await this.loadEvents();
  },
  methods: 
  {
    onEditEvent(rowData)
    {
      if(this.ignoreRowAction)
      {        
          this.ignoreRowAction = false;
          return;
      }
        
      this.$router.push({name:'addEditEvent', params:{type:'edit', event:rowData}});
    },
    gotoAdmin(){
      this.$router.push({name: 'Admin'});
    },
    onAddEvent()
    {
      console.log("Go to addEditEvent page to add a new event");
      this.$router.push({name:'addEditEvent', params:{type:'add'}});
    },
    async confirmAllEventDeletion()
    {
      var deleteConfirmed = 
      await this.$confirm("Are you sure?", "Delete historic events", "question");
      if(deleteConfirmed)
      {
        this.gotoDeleteOldEvents();
      }
      else
      {
        console.log("Delete all events cancelled");
      } 
    },
    async gotoDeleteOldEvents()
    {
        console.log("Delete all old events and reload");
        await data.deleteOldEvents();
        if(data.hasError())
        {
            console.log("Failed to delete historic events with error ");
            this.$confirm("Failed to delete historic events", "Error", "error");
            return;
        }
        console.log("Historic events successfully deleted");
        this.$confirm("All historic events have been deleted", "Success", "success");
    },

    async onDeleteEvent(rowData)
    {        
        this.ignoreRowAction = true;
        console.log("Deleting event " +rowData.name);
        //delete event from the database
        var success = await data.deleteEvent(rowData.eventId);
        if(success)
        {
          //delete event from the table
            var index = this.events.findIndex(e => e.eventId == rowData.eventId);
            this.events.splice(index, 1);
            
            this.$confirm(rowData.name + " has been successfully deleted", "Success", "success");
        }
        else
        {
          this.$confirm("Failed to delete event " + rowData.name, 'Error', 'error');
          console.log("Error " + data.getErrorMessage());
        }
    },
    sortDate(a,b,key, sortDesc) {
      return display.sortDate(a,b,key,sortDesc);
    },
    getFriendlyDate(value){
        return display.getFriendlyDate(value);
    },
    validEvents(){
      return this.events.filter(event => !event['deleted']);
    },
    async updateConfirmStatus(rowData){
        this.ignoreRowAction = true;
        var success = await data.postUpdateConfirmedStatus(rowData.eventId, rowData.confirmed);
        if(success)
        {
            console.log("Succcessfully upated confirmed status to " + rowData.confirmed +" for event " + rowData.eventId);            
        }
        else
        {
          console.log("Error updating confirmed status for " + rowData.eventId);
          console.log("Error = " + data.getErrorMessage());
        }
    },
    async loadEvents(){
      this.events =[];
      this.loadingEvents = true;
      console.log('request events');
      var event = await data.getInvitedEvents();      
      this.loadingEvents = false;

      if(data.hasError())
      {                
          this.message = '';
          this.errorMessage = "ERROR - " + data.getErrorMessage();                
          return;
      }
      
      this.events = event.editingEventData.events;      
      if (this.events.length <= 0) 
      {
        console.log("No events returned");
        this.message = "The team currently have no invited events.";
      }
      else
      {
        console.log(this.events.length + " events returned");
        this.message = '';
      }
      
    }
  },
};
</script>
<style lang="css" scoped>
  @import "./../design/general.scss";
  @import "./../design/table.scss";
  
::v-deep td{
  cursor:pointer;
  white-space: nowrap;
}

.displayTextboxIE{  
   padding: 5em 1em 2em 1em;
   width:100%;
}
</style>