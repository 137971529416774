<template>
<div class="container">
      <div class="title titleSingle">
            <div></div>
            <div>
                <h3 class="titleText">
                    {{type}} Event
                </h3>
            </div>
            <div></div>
        </div>
  <div class="containerTop">
    <div class="divAlign">      
        <v-row>        
          <v-col cols="12">
            <v-card class="pa-3">              
              <v-card-text>
                <v-text-field
                  label="Title"
                  v-model="title"
                  ref="title"
                  :rules="titleRules"
                >
                </v-text-field>
                <v-text-field label="Address" v-model="address" ref="address">
                </v-text-field>
                <v-menu
                  v-model="menuStartDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="getWeekDayDate(startDate)"
                      v-model="startDate"
                      label="Start Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    @input="menuStartDate = false"
                    @change="updateEndDate"
                  ></v-date-picker>
                </v-menu>
                <v-menu
                  v-model="menuEndDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="getWeekDayDate(endDate)"
                      v-model="endDate"
                      label="End Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    @input="menuEndDate = false"
                    @change="updateDayPicker"
                  ></v-date-picker>
                </v-menu>
                <v-select
                  :items="dayOptions"
                  label="Attending Days"
                  multiple
                  v-model="selectedDays"
                ></v-select>
                <v-text-field
                  label="Website Address"
                  v-model="url"
                ></v-text-field>
                <v-checkbox
                  v-model="confirmed"
                  label="Event Confirmed"
                  color="green"
                ></v-checkbox>
                <v-textarea
                  label="Additional Info"
                  v-model="additionalInfo"
                ><pre></pre></v-textarea>
              </v-card-text>
              <v-checkbox v-model="resetAttendance" label="Reset member attendance" color="blue"> </v-checkbox>
              <v-divider class="mt-12"></v-divider>
              <v-card-actions>
                <v-btn color="blue-grey" elevation="10" @click="onCancel">Back</v-btn>
                <v-spacer></v-spacer>
                <v-btn v-if="canUpgradeEvent" color="purple lighten-3"  elevation="10" @click="onUpgradeEvent">Upgrade to Invited</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green" elevation="10" @click="onSubmit"> Submit </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>      
    </div>
  </div>
</div>
</template>

<script>
import { display } from "../shared/display";
import { data } from "../shared/data";
import {
  differenceInDays,
  compareDesc,
  addDays,
  format,
  parseISO,
} from "date-fns";

export default {
  name: "addEditEvent",
  props: ["eventData"],
  data() {
    return {
      inputValid: false,
      type: '',
      eventId: 0,
      title: "",
      address: "",
      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menuStartDate: false,
      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menuEndDate: false,
      dayOptions: [],
      weekDayLookup: [
        ["Monday", 1],
        ["Tuesday", 2],
        ["Wednesday", 4],
        ["Thursday", 8],
        ["Friday", 16],
        ["Saturday", 32],
        ["Sunday", 64],
        ["overAWeek", 128]
      ],
      selectedDays: [],
      friendlyDate: "",
      confirmed: 0,
      invited: 1,
      url: "",
      additionalInfo: "",
      resetAttendance: false,
      titleRules:[
          text => !!text || "This field is required",
          text => (text && text.length <= 50 || "Title should be less than 50 characters"),
          text => (text && text.length > 1 || "Title must be longer than 1 character") ]
    };
  },
  async created() {
    var type = this.$route.params.type;
    if (type === "edit") 
    {
      console.log("Edit event");
      this.type = 'Edit';
      this.eventId = this.$route.params.event.eventId;
      if(this.eventId === 0)
      {
          this.errorMessage = 'Invalid EventId ' + this.eventId;
          return;
      }
      var event = await data.getEventInfo(this.eventId);
      var eventData = event.event;
      if(data.hasError())
      {
          this.message = '';
          this.errorMessage = "ERROR - " + data.getErrorMessage();
          return;
      }
      
      this.title = eventData.title;
      this.address = eventData.address;
      this.startDate = eventData.startDate;
      this.endDate = eventData.endDate;
      this.confirmed = eventData.confirmed === undefined ? false : eventData.confirmed;
      this.additionalInfo = eventData.additionalInfo; 
      this.url = eventData.url;    
      this.invited = eventData.invited; 

      this.updateDayPicker();
      for (var i = 0; i < this.dayOptions.length; i++) {
        if (this.dayOptions[i].value & eventData.danceDays) {
          this.selectedDays.push(this.dayOptions[i].value);
        }
      }
      console.log("data added for event " + this.title);
    } 
    else if (type === "add") 
    {
      this.type = 'Add';
      this.invited = true;
      console.log("Added new event, all data cleared");
    }
    else if(type === 'addToWish')
    {
      this.type = 'Add';
      this.ivited = false;
      console.log("Add a new event to wish list");
    }
    else 
    {
      console.log("Error undefined page type");
      //throw error
    }
  },

  methods: { 
    isAdmin(){
      return this.$store.getters.isLoggedIn && this.$store.getters.getAdminStatus; 
    },
    canUpgradeEvent(){
      return this.isAdmin() && this.type === 'Edit' && this.invited === false;
    },
    async onSubmit() {
      var selectedDayMask = 0;
      if(this.validSubmitData())
      {
        for (var i = 0; i < this.selectedDays.length; i++) 
        {
          selectedDayMask += this.selectedDays[i];
        }
        var newEvent =
        {
          eventId: this.eventId,
          title: this.title,
          address: this.address,
          additionalInfo: this.additionalInfo,
          startDate: this.startDate,
          endDate: this.endDate,
          confirm: this.confirmed,
          invited: this.invited,
          dayOptions: selectedDayMask,
          url: this.url,
        };
        var success;
        if(this.type == 'Edit')
        {
            newEvent['deleteAttendance'] = this.resetAttendance;
            success = await data.postEventUpdate(newEvent);
        }
        else if(this.type == 'Add')
        {
            success = await data.postEventAdd(newEvent);
        }
        else
        {
          console.log("ERROR - unknown page type - " + this.type);
        }
        
        if (success) 
        {
          this.$confirm("Event successfully " + (this.type =='Add' ? 'Added' : 'Updated'), 'Success', 'success');
          this.$router.push({ name: "Admin" });
        } 
        else if(this.type == 'Edit')
        {
          this.$confirm("Event updated failed ", 'Error', 'error');
          console.log("Event updated failed - " + data.getErrorMessage());
        }
        else
        {
          this.$confirm("Event creation failed ", 'Error', 'error');
          console.log("Event add failed - " + data.getErrorMessage());
        }
      }
    },
    onCancel(){
      if(this.type == 'Edit')
      {
          this.$router.push({name: 'InvitedEvents'});
      }
      else if(this.type == 'Add')
      {
          this.$router.push({name: 'Admin'});
      }
    },
    getWeekDayDate(date) {
      return display.getFriendlyDate(date);
    },
    updateDayPicker() {
      var startDate = parseISO(this.startDate);
      var endDate = parseISO(this.endDate);
      var eventLength = differenceInDays(endDate, startDate);
      this.dayOptions = [];
     // if (dayDiff < 7) {
       // const mapWeekDay = new Map(this.weekDayLookup);
        var nextDay = startDate;
        var daysOverAWeek = 0;
        while (compareDesc(nextDay, endDate) != -1) 
        {
          var weekDayDisplay = eventLength < 7 ?format(nextDay, "EEEE") : format(nextDay, "EEEE") + "-" + format(nextDay, "dd");
          var weekDayValue =  display.getWeekDayKey(format(nextDay, "EEEE"));
          if(differenceInDays(nextDay, startDate) >= 7)
          {
            //if the nextDay is over a week apart from the start day, use the days over mask
            weekDayValue = 128 << daysOverAWeek;
            ++daysOverAWeek;
          }
          var day = { text: weekDayDisplay, value: weekDayValue};
          this.dayOptions.push(day);          
          nextDay = addDays(nextDay, 1);
        }
     // }
    },
    updateEndDate(){
      if(this.startDate > this.endDate)
      {
        this.endDate = this.startDate;
      }
      else
      {
        this.updateDayPicker();
      }
    },
    validSubmitData()
    {
        var startDate = Date.parse(this.startDate);
        var endDate = Date.parse(this.endDate);
        var now = Date.now();
        if(this.title.length >= 50)
        {
          this.$confirm("Event title is longer than 50 character", "Error", "error");
           return false;
        }
        else if(this.title.length <= 3)
        {
          this.$confirm("The event title must be longer than 3 characters", "Error", "error");
          return false;
        }
        else if (startDate <= now)
        {
          this.$confirm("The start date must be in the future", "Error", "error");
          return false;
        }
        else if(endDate < startDate)
        {
          this.$confirm("The start date must be after the end date", "Error", "error");
          return false;
        } 

      return true;
    }
  },
};
</script>

<style>
.form {
  border-radius: 50px;
}
.topRow {
  height: 20px;
}
.divAlign {
  margin: 0 auto;
  width: 50%;
}
@media screen and (max-width:500) {
  .divAlign
  {
    width: 100%;
  }
}
.containerTop {  
  display: flex;
  justify-content: center;  
  padding: 7em 1em 2em 1em;  
  box-shadow:  0px 0px 20px 10px rgba(0,0,0,0.6);
 
}


</style>
