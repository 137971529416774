<template>
  <div>
    <div class="overlayInfo">
      <v-btn class="closeBtn" plain @click="onClose">X</v-btn>
      <div class="overlayTitle">
        {{ title }}
      </div>
      <div class="textBlocks">
        <b>{{ date }}</b>
      </div>
      <div class="textBlocks">
        {{ address }}
      </div>
      <div class="textBlocks">
        <a v-bind:href="url" target="_blank">{{ url }}</a>
      </div>
      <div class="subtextBlock" v-if="dancingDays !== 0">
        The team will be dancing on <b> {{ dancingDays }}</b>
      </div>
      <div class="header">
        <v-expansion-panels v-model="panel" :readonly="readonly" multiple>
          <div v-if="additionalInfo" style="overflow: auto">
            <v-expansion-panel>
              <v-expansion-panel-header
                >Additional Information</v-expansion-panel-header
              >
              <v-expansion-panel-content style="white-space: pre-wrap">{{
                additionalInfo
              }}</v-expansion-panel-content>
            </v-expansion-panel>
          </div>
          <div style="overflow: auto">
            <v-expansion-panel expand>
              <v-expansion-panel-header style="padding:0em 1em 0em 1em">
                <span class="ReportTitle">Attendance Report</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list-group
                  v-for="day in report"
                  :key="day.title"
                  v-model="day.active"
                  no-action
                  class="listGroupDense">
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title v-text="day.title"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-group
                    v-for="type in day.items"
                    :key="type.title"
                    v-model="type.active"
                    no-action
                    sub-group
                    style="padding:0px">
                    <template v-slot:activator>
                      <v-list-item-content style="padding:0px; min-height;auto;">
                        <v-list-item-title
                          v-text="type.title"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <v-list-item
                      v-for="member in type.members"
                      :key="member.title"
                    >
                    <v-list-item-content style="padding:0px; min-height:auto;">
                        <v-list-item-title
                          v-text="member.title"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                </v-list-group>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </div>
        </v-expansion-panels>
      </div>
      <div
        class="alert alert-danger"
        style="text-align: center"
        role="alert"
        v-show="errorMessage"
      >
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "./../design/eventOverlay.scss"
</style>

<script>
import { data } from "../shared/data";
import { display } from "../shared/display";
import { format, compareDesc, parseISO, addDays, isValid, differenceInDays } from "date-fns";

export default {
  name: "EventInfo",
  data() {
    return {
      title: "",
      address: "",
      date: "",
      url: "",
      dancingDays: "",
      additionalInfo: "",
      errorMessage: "",
      report: [],
      items2: [
        {
          title: "Friday",
          items: [
            {
              title: "Musicians (2)",
              members: [{ title: "Sarah" }, { title: "Jo" }],
              active: true,
            },
            {
              title: "Dancers (2)",
              members: [{ title: "Adam" }, { title: "Polly" }],
              active: true,
            },
          ],
        },
        {
          title: "Saturday",
          items: [
            {
              title: "Musicians (3)",
              members: [{ title: "Sarah" }, { title: "Jo" }, { title: "Tone" }],
              active: true,
            },

            {
              title: "Dancers (4)",
              members: [
                { title: "Adam" },
                { title: "Polly" },
                { title: "George" },
                { title: "Sally" },
              ],
              active: true,
            },
          ],
        },
        {
          title: "Sunday",
          items: [
            {
              title: "Musicians (3)",
              members: [{ title: "Sarah" }, { title: "Jo" }, { title: "Tone" }],
              active: true,
            },
            {
              title: "Dancers (4)",
              members: [
                { title: "Adam" },
                { title: "Polly" },
                { title: "George" },
                { title: "Sally" },
              ],
              active: true,
            },
          ],
        },
      ],
    };
  },
  props: {
    eventId: {
      type: Number,
      default: 0,
    },
  },
  async created() {
    //this.eventId = this.$route.params.eventId;
    console.log("loading event info for event id " + this.eventId);
    await this.loadEventInfo();
    await this.loadAttendanceReport();
  },
  methods: {
    async loadEventInfo() {
      if (this.eventId === 0) {
        this.errorMessage = "No Event Id supplied";
        return;
      }

      var eventInfo = await data.getEventInfo(this.eventId);
      if (data.hasError()) {
        this.message = "";
        this.errorMessage = "ERROR - " + data.getErrorMessage();
        return;
      }
      this.title = eventInfo.event.title;
      this.address = eventInfo.event.address;
      this.url = eventInfo.event.url;
      
      if (this.url !== null && !this.url.includes("http")) 
      {
        console.log("url is invaild, it will not be display, url - " + this.url);
        this.url = "";
      }
      this.additionalInfo = eventInfo.event.additionalInfo;
      /*if(this.additionalInfo == undefined || this.additionalInfo === "")
            {
                this.additionalInfo = "No additional infomation provided";
            }*/
      this.date = display.getFriendlyDate(eventInfo.event.startDate);
      if ((eventInfo.event.endDate !== null)&&(eventInfo.event.startDate !== eventInfo.event.endDate)) 
      {
        this.date += " - " + display.getFriendlyDate(eventInfo.event.endDate);
      }
      this.dancingDays = this.formatDancingDays(
        eventInfo.event.danceDays,
        eventInfo.event.startDate,
        eventInfo.event.endDate
      );
    },
    async loadAttendanceReport() {
      console.log("Loading attendance report");
      var response = await data.getAttendanceReport(this.eventId);
      if (data.hasError()) {
        this.message = "";
        this.errorMessage = "ERROR - " + data.getErrorMessage();
        return;
      }

      var eventReport = response.eventReport;

      if(eventReport.length === 0)
      {
        console.log("Event attendance is empty");
        return;
      }

      var startDate = parseISO(eventReport.startDate);
      var endDate = parseISO(eventReport.endDate);

      var dayDiff = differenceInDays(endDate, startDate);
      while (compareDesc(nextDay, endDate) != -1)
      {
        //get the day
        var nextDay = startDate;      
        while (compareDesc(nextDay, endDate) != -1)
        {
          //get the day of the week and add the date if over a week to display
          var weekDayDisplay = dayDiff < 7 ?format(nextDay, "EEEE") : format(nextDay, "EEEE") + "-" + format(nextDay, "dd");

          // get the key for the day of the week
         // var weekDayKey = display.getWeekDayKey(format(nextDay, "EEEE"));

          if(eventReport[weekDayDisplay] != undefined)
          {
            this.createDayObject(weekDayDisplay, eventReport[weekDayDisplay]);
          }
          nextDay = addDays(nextDay, 1);
        }
      }
     /* if(eventReport.monday != undefined)
      {
        this.createDayObject("Monday", eventReport.monday);
      }
      if(eventReport.tuesday != undefined)
      {
        this.createDayObject("Tuesday", eventReport.tuesday);
      }
      if(eventReport.wednesday != undefined)
      {
        this.createDayObject("Wednesday", eventReport.wednesday);
      }
      if(eventReport.thursday != undefined)
      {
        this.createDayObject("Thursday", eventReport.thursday);
      }
      if(eventReport.friday != undefined)
      {
        this.createDayObject("Friday", eventReport.friday);
      }
      if(eventReport.saturday != undefined)
      {
        this.createDayObject("Saturday", eventReport.saturday);
      }
      if(eventReport.sunday != undefined)
      {
        this.createDayObject("Sunday", eventReport.sunday);
      }*/
    
      if (this.report.length === 0) {
        this.createDayObject(this.dancingDays, eventReport.attendance);
      }

      const myJson = JSON.stringify(this.report);
      console.log("JSON = " + myJson);
    },
    createDayObject(title, dayObj) {
      if (dayObj) 
      {
        var object = {};
        object["title"] = title;        

        object["items"] = [];
        var dancers = this.createTypeObject(dayObj.dancer, "Dancers");
        var musicians = this.createTypeObject(dayObj.musician, "Musicians");

        if (dancers !== undefined) 
        {    
          //sort and add members        
            dancers.members.sort((a, b) =>
            {
              return this.sortAttendance(a,b);
            });  
            object.items.push(dancers);
        }

        if (musicians !== undefined) 
        {
          //sort and add musicians
            musicians.members.sort((a,b) =>
            {
              return this.sortAttendance(a,b);
            });
            object.items.push(musicians);
        }
        this.report.push(object);
              
      }
    },
    sortAttendance(a,b)
    {
      const nameA = a.title.toUpperCase();
      const nameB = b.title.toUpperCase();
      if(nameA > nameB)
      {
        return 1;
      }
      else if(nameA < nameB)
      {
        return -1;          
      }
      return 0;
    },
    createTypeObject(typeObj, title) {
      if (typeObj) {
        var item = {};
        var count = typeObj.length;

        var members = [];
        for (var i = 0; i < typeObj.length; i++) {
          var member = {};
          member["title"] = typeObj[i];
          members.push(member);
        }
        item["title"] = title + " (" + count + ")";
        item["members"] = members;
        item["active"] = true;
      }
      return item;
    },

    formatDancingDays(selectedDays, startDate, endDate) {
      var dancingDays = [];
      var dayString = "";
      var nextDay = parseISO(startDate);
      var end = parseISO(endDate);

      if (!isValid(end))
      {
        if (!isValid(nextDay)) 
        {
          this.errorMessage = "invalid date parameters";
          return;
        }
        return format(nextDay, "EEEE");
      }
      while (compareDesc(nextDay, end) != -1) 
      {
        var weekDay = format(nextDay, "EEEE");
        if (display.getWeekDayKey(weekDay) & selectedDays) 
        {
          console.log("add weekday " + weekDay + ", for select =" + selectedDays);
          dancingDays.push(weekDay);
        }
        else
        {
          console.log("weekday "+ weekDay + "not added for selection "+ selectedDays);
        }
        nextDay = addDays(nextDay, 1);
      }
      console.log("dancing day length = "+dancingDays.length);
      if (dancingDays.length == 0) 
      {
        var startDay = parseISO(startDate);
        dayString = format(startDay, "EEEE"); 
        return dayString;
      } 
      else if (dancingDays.length === 1) 
      {
        dayString = dancingDays[0];
      } 
      else 
      {
        for (var i = 0; i < dancingDays.length; i++) 
        {
          if (i + 1 == dancingDays.length) 
          {
            dayString += dancingDays[i];
          } 
          else 
          {
            if (dancingDays.length > 2) 
            {
              dayString += dancingDays[i] + ", ";
            } 
            else 
            {
              dayString = dancingDays[i] + " & ";
            }
          }
        }
      }
      return dayString;
    },
    onClose() {
      this.$emit("click");
    },
  },
};
</script>